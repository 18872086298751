import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccueilComponent } from './app/components/accueil/accueil.component';
import { AccessFavorisGuard } from './app/components/favoris/accessFavorisGuard';
import { FavorisComponent } from "./app/components/favoris/favoris.component";
import { AccessibiliteComponent } from './app/components/footer/accessibilite/accessibilite.component';
import { DonneesPersonnellesComponent } from './app/components/footer/donnees-personnelles/donnees-personnelles.component';
import { GestionCookiesComponent } from './app/components/footer/gestion-cookies/gestion-cookies.component';
import { AideComponent } from './app/components/header/aide/aide.component';
const routes:Routes =[
    {
      path: 'accueil', component:AccueilComponent, title:'WAYF Native | Accueil'
    },
    {
      path: 'favoris', component: FavorisComponent, canActivate: [AccessFavorisGuard], title:'WAYF Native | Favoris'
    },
    {
      path: 'page/accessibilite', component:AccessibiliteComponent, title:'WAYF Native | Accessibilité'
    },
    {
      path: 'page/donneespersonnelles', component:DonneesPersonnellesComponent, title:'WAYF Native | Données personnelles'
    },
    {
      path: 'page/gestiondescookies', component:GestionCookiesComponent, title:'WAYF Native | Gestion des cookies'
    },
    {
      path: 'aide', component:AideComponent, title:'WAYF Native | Aide'
    },
    {
      path: '**', redirectTo: 'accueil', pathMatch: 'full'
  },


]
@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
