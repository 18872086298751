<div class="fr-grid-row fr-grid-row--gutters">
    <div class="fr-col-12">
        <label class="champ_title" for="commune-cp">{{ 'ACCUEIL.SAISIE_LIEU_ETAB' | translate }}</label>
    </div>
    <div class="fr-col-12">
        <span class="sub_title" id="commune-sous-titre">{{ 'ACCUEIL.SAISIE_NOM_CODE_POSTAL' | translate }}</span>
    </div>
</div>
<div class="fr-grid-row fr-grid-row--gutters" >
    <div class="fr-col-12 fr-col-sm-10 fr-col-md-7 fr-col-lg-8 fr-col-xl-8" >
    <form >
        <mat-form-field >

        <input
            #commune
            id="commune-cp"
            type="text"
            aria-label="Champ code postal ou commune"
            aria-describedby="commune-sous-titre"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
            [matAutocompletePosition]="'below'"

            (ngModelChange)="logEvent()"
            (paste)="onPaste()"


        />
        <button
            matSuffix
            mat-icon-button
            aria-label="Effacer le champ code postal ou nom de la commune"
            *ngIf="myControl.value"
            matSuffix
            mat-icon-button
            (click)="reset()"
        >
          <img alt="" aria-label="Reinitialisation des champs" src="../../../../assets/img/fermer.svg">
        </button>
            <mat-autocomplete   #auto="matAutocomplete">
                <mat-option *ngIf="showResultSize" class="result-class" attr.aria-label="{{ 'ACCUEIL.RESULTAT_WEB_SERVICE_COMMUNE' | translate }} ({{resultSize }}" disabled >{{errorOption}} {{ 'ACCUEIL.RESULTAT_WEB_SERVICE_COMMUNE' | translate }} ({{resultSize }})</mat-option>
                <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
                (click)="selectCommune(option)"
                (onSelectionChange)="selectCommune(option)"
                attr.aria-label="{{option}}"
                >
                {{option}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
  </div>
  <div class="fr-col-12 fr-col-sm-10 fr-col-md-5 fr-col-lg-4 fr-col-xl-4 resultSizeStyle" *ngIf="isLoading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
 </div>


