import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retour-accueil',
  templateUrl: './retour-accueil.component.html',
  styleUrls: ['./retour-accueil.component.css']
})
export class RetourAccueilComponent implements OnInit {

  showLastConnetion = false;
  constructor() { }

  ngOnInit(): void {
    Object.keys(localStorage).forEach(el => {
      let dateList = el.split('_')
      if (dateList && dateList.length == 2 && dateList[0] == 'wayfNative') {
        this.showLastConnetion = true
      }
    })
  }

}
