<mat-card class="main-card">
  <div class="fr-col-12">
    <div class="headerTuile">
      <img class="imageTuile" alt="profil" aria-label="Affichage du profil de connexion" [src]="profil != 'AGENT' ? PATH_IMG_ELEVE : PATH_IMG_AGENT">
    </div>
    <mat-card-content class="fr-col-12">
      <div class="profilEtEtablissement" *ngIf="profil">
        {{profil}}
      </div>
      <div class="profilEtEtablissement" *ngIf="typeEtablissement">
        {{typeEtablissement}}
      </div>
      <div id="labelEtab" class ="informationEtablissement"
           title="{{etablissement.nomEtablissement}} - {{etablissement.nomCommune}} {{etablissement.codePostal}}"
           attr.aria-label="{{etablissement.nomEtablissement}} - {{etablissement.nomCommune}} {{etablissement.codePostal}}">
        {{etablissement.nomEtablissement}} - {{etablissement.nomCommune}} {{etablissement.codePostal}}
      </div>
    </mat-card-content>
    <mat-card-actions>
        <button id="buttonConnecter" (click)="redirectToIdp()" class="buttonConnection">{{'FAVORIS.SE_CONNECTER' | translate}}</button>
    </mat-card-actions>
  </div>
</mat-card>
